import React from 'react';
import '../Styling/Vendor.css'; // Import the same CSS file
import Header from './Header';
import Footer from './Footer';

const VendorPMI = () => {
  return (
    <>
    <Header />
    <div className="vendor-page">
      {/* Vendor Name Section */}
      <section className="vendor-name">
        <h1>PMI Certifications</h1>
        <p style={{textAlign:'left'}}>The Project Management Institute (PMI) is a globally recognized professional organization dedicated to advancing the discipline of project management. PMI offers a variety of certifications that validate your knowledge, skills, and abilities in project management, making them highly valued by employers around the world. PMI's mission is to promote the practice, science, and profession of project management by providing standards, certifications, and educational opportunities</p>
        <a href="https://wa.me/919890142308" className="whatsapp-button">
            <span role="img" aria-label="whatsapp">💬</span> Enquire Now
          </a>
      </section>

      {/* Certifications Section */}
      <section className="certifications">

        <div className="certification-grid">
          <div className="certification-item">
            <img src={require('../Image/PMI/PMI Agile Certified Practitioner.png')} alt="Certification 1" />
            <p>PMI Agile Certified Practitioner</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/PMI/PMI Risk Management Professional.png')} alt="Certification 2" />
            <p>PMI Risk Management Professional</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/PMI/PMI Project Management Professional.jpg')} alt="Certification 3" />
            <p>PMI Project Management Professional</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/PMI/PMI Certified Associate in Project Management.png')} alt="Certification 3" />
            <p>PMI Certified Associate in Project Management</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/PMI/PMI Professional in Business Analysis.png')} alt="Certification 3" />
            <p>PMI Professional in Business Analysis</p>
          </div>
        </div>
      </section>
      {/* WhatsApp Icon */}
      <a
          href="https://wa.me/919890142308" // Your WhatsApp number
          className="whatsapp-float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('../Image/newWhatsapp.png')} alt="WhatsApp" />
        </a>
    </div>
    <Footer />
    </>
  );
};

export default VendorPMI;