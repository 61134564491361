import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import VendorMicrosoft from './Components/VendorMicrosoft';
import VendorAWS from './Components/VendorAWS';
import VendorGoogle from './Components/VendorGoogle';
import VendorComptia from './Components/VendorComptia';
import VendorPMI from './Components/VendorPMI';
import VendorIIBA from './Components/VendorIIBA';
import AboutUs from './Components/AboutUs';
import VendorsPage from './Components/VendorsPage';

function App() {
  return (
    <>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/microsoft" element={<VendorMicrosoft />} />
            <Route path="/aws" element={<VendorAWS />} />
            <Route path="/google-cloud" element={<VendorGoogle />} />
            <Route path="/comptia" element={<VendorComptia />} />
            <Route path="/pmi" element={<VendorPMI />} />
            <Route path="/iiba" element={<VendorIIBA />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/vendors" element={<VendorsPage />} />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
