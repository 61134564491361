

// import React, { useState } from 'react';
// import '../Styling/Header.css';
// import { Link } from 'react-router-dom';
// import logo from '../Image/headerLogo.png'

// const Header = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(prevIsOpen => !prevIsOpen);
//   };

//   const toggleDropdown = () => {
//     setIsDropdownOpen(prevIsDropdownOpen => !prevIsDropdownOpen);
//   };

//   return (
//     <header className="header">
//       <Link to='/'><div className="logo"><img src={logo} alt="Logo" /></div></Link>
//       <nav className={`nav ${isOpen ? 'open' : ''}`}>
//         <Link to="/">Home</Link>
        
//         <div className="dropdown" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
//           <a href="/vendors" className="dropdown-link">Courses</a>
//           {isDropdownOpen && (
//             <div className="dropdown-content">
//               <Link to="/microsoft">Microsoft</Link>
//               <Link to="/aws">AWS</Link>
//               <Link to="/google-cloud">Google Cloud</Link>
//               <Link to="/comptia">CompTIA</Link>
//               <Link to="/pmi">PMI</Link>
//               <Link to="/iiba">IIBA</Link>
//             </div>
//           )}
//         </div>
//         <a href="/aboutus">About Us</a>
        
//       </nav>
//       <div className="hamburger" onClick={toggleMenu}>
//         <div className="line"></div>
//         <div className="line"></div>
//         <div className="line"></div>
//       </div>
//     </header>
//   );
// };

// export default Header;






import React, { useState } from 'react';
import '../Styling/Header.css';
import { Link } from 'react-router-dom';
import logo from '../Image/headerLogo.png'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(prevIsDropdownOpen => !prevIsDropdownOpen);
  };

  return (
    <header className="header">
      <Link to='/'><div className="logo"><img src={logo} alt="Logo" /></div></Link>
      <nav className={`nav ${isOpen ? 'open' : ''}`}>
        <Link to="/">Home</Link>
        
        <div className="dropdown" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
          <a href="/vendors" className="dropdown-link">IT Training</a>
          {isDropdownOpen && (
            <div className="dropdown-content">
              <Link to="/microsoft">Microsoft</Link>
              <Link to="/aws">AWS</Link>
              <Link to="/google-cloud">Google Cloud</Link>
              <Link to="/comptia">CompTIA</Link>
              <Link to="/pmi">PMI</Link>
              <Link to="/iiba">IIBA</Link>
            </div>
          )}
        </div>
        <a href="/aboutus">About</a>
      </nav>
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </header>
  );
};

export default Header;
