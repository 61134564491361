import React, { useState, useEffect } from 'react';
import '../Styling/AboutUs.css';
import Footer from './Footer';
import Header from './Header';
import image from '../Image/emp.avif'
import { Link } from 'react-router-dom';

const AboutUs = () => {

    const [activeIndex, setActiveIndex] = useState(0);
    const vendors = [
        { name: "Microsoft", img: require('../Image/microSoft.jpg'), link: "/microsoft" },
        { name: "AWS", img: require('../Image/AWS.jpg'), link: "/aws" },
        { name: "Google Cloud", img: require('../Image/googleCloud.jpg'), link: "/google-cloud" },
        { name: "CompTIA", img: require('../Image/compTia.png'), link: "/comptia" },
        { name: "PMI", img: require('../Image/PMI.jpg'), link: "/pmi" },
        { name: "IIBA", img: require('../Image/IIBA.jpg'), link: "/iiba" },
        { name: "Salesforce", img: require('../Image/salesforce.jpeg'), link: "/" },
        { name: "CIA", img: require('../Image/cia.png'), link: "/" },
        { name: "Cisco", img: require('../Image/cisco.png'), link: "/" },
        { name: "ISACA", img: require('../Image/isaca.jpeg'), link: "/" },
        { name: "Oracle", img: require('../Image/oracle.png'), link: "/" },
        { name: "SAFe", img: require('../Image/safe.png'), link: "/" },
        { name: "ServiceNow", img: require('../Image/servicenow.png'), link: "/" },
        { name: "GAQM", img: require('../Image/gaqm.jpg'), link: "/" },
        { name: "SAP", img: require('../Image/sap.png'), link: "/" },
      ];

      const scrollLeft = () => {
        setActiveIndex((prevIndex) => (prevIndex - 1 + vendors.length) % vendors.length);
      };
    
      const scrollRight = () => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % vendors.length);
      };
    
      const getVendorClass = (index) => {
        if (index === activeIndex) return 'active';
        if (index === (activeIndex - 1 + vendors.length) % vendors.length) return 'left';
        if (index === (activeIndex + 1) % vendors.length) return 'right';
        return '';
      };

  return (
    <>
    <Header />
    <div className="about-us">
      <header className="about-us-header">
        <h1>About Us</h1>
        <p>Your Partner in IT Certification Training</p>
      </header>
      <section className="about-us-content">
        <div className="about-us-image">
          <img src={image} alt="About Us" />
        </div>
        <div className="about-us-text">
          <h2>Who We Are ?</h2>
          <p>We are dedicated to empowering your IT career with top-notch training from experienced professionals. Our mission is to provide the best learning experience, ensuring that you gain the skills and certifications needed to excel in the IT industry.</p>
          <h2>Our Mission</h2>
          <p>To deliver world-class IT training that helps you achieve your career goals. We focus on quality, innovation, and a learner-centric approach to ensure you get the best possible outcomes from our programs.</p>
        </div>
      </section>
      
      {/* Vendor List Section */}
      <section className="vendors">
        <h2 className='vendor-page-title'>Courses</h2>
        <div className="vendor-container">
          <button className="scroll-button left" onClick={scrollLeft}>❮</button>
          <div className="vendor-grid">
            {vendors.map((vendor, index) => (
              <Link to={vendor.link} className={`vendor-item ${getVendorClass(index)}`} key={index}
                style={{ backgroundImage: `url(${vendor.img})` }}>
                {/* <div className="know-more">Know More</div> */}
              </Link>
            ))}
          </div>
        <button className="scroll-button right" onClick={scrollRight}>❯</button>

            {/* Dynamic Dots */}
            {/* <div className="dots-navigation">
              {vendors.map((_, index) => (
                <span
                  key={index}
                  className={`dot ${index === activeIndex ? 'active' : ''}`}
                  onClick={() => setActiveIndex(index)}
                ></span>
              ))}
            </div> */}
          </div>
        </section>

      <section className="our-values">
        <h2 className='vendor-page-title'>Our Values</h2>
        <div className="values-grid">
          <div className="value-item">
            <h3>Quality</h3>
            <p>"We prioritize quality in our training programs to ensure you receive the best education possible."</p>
          </div>
          <div className="value-item">
            <h3>Integrity</h3>
            <p>"We operate with the highest level of integrity, ensuring transparency and honesty in all our dealings."</p>
          </div>
          <div className="value-item">
            <h3>Innovation</h3>
            <p>"We constantly innovate to provide cutting-edge training that keeps you ahead in the IT industry."</p>
          </div>
          <div className="value-item">
            <h3>Commitment</h3>
            <p>"We are committed to your success, offering support and guidance every step of the way."</p>
          </div>
        </div>
      </section>
      {/* WhatsApp Icon */}
      <a
          href="https://wa.me/919890142308" // Your WhatsApp number
          className="whatsapp-float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('../Image/newWhatsapp.png')} alt="WhatsApp" />
        </a>
    </div>
    <Footer />
    </>
  );
};

export default AboutUs;
