import React from 'react';
import '../Styling/Vendor.css'; // Import the same CSS file
import Header from './Header';
import Footer from './Footer';

const VendorIIBA = () => {
  return (
    <>
    <Header />
    <div className="vendor-page">
      {/* Vendor Name Section */}
      <section className="vendor-name">
        <h1>IIBA Certifications</h1>
        <p style={{textAlign:'left'}}>The International Institute of Business Analysis (IIBA) is a professional association dedicated to supporting the field of business analysis. IIBA offers several certification levels to cater to different stages of a business analyst's career. These certifications are recognized worldwide and are based on the BABOK Guide.</p>
        <a href="https://wa.me/919890142308" className="whatsapp-button">
            <span role="img" aria-label="whatsapp">💬</span> Enquire Now
          </a>
      </section>

      {/* Certifications Section */}
      <section className="certifications">

        <div className="certification-grid">
          <div className="certification-item">
            <img src={require('../Image/IIBA/IIBA Certified ECBA.webp')} alt="Certification 1" />
            <p>IIBA Certified ECBA</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/IIBA/IIBA Certified AAC.webp')} alt="Certification 2" />
            <p>IIBA Certified AAC</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/IIBA/IIBA Certified CBAP.webp')} alt="Certification 3" />
            <p>IIBA Certified CBAP</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/IIBA/IIBA Certified CBDA.webp')} alt="Certification 3" />
            <p>IIBA Certified CBDA</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/IIBA/IIBA Certified CCA.webp')} alt="Certification 3" />
            <p>IIBA Certified CCA</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/IIBA/IIBA Certified CCBA.webp')} alt="Certification 3" />
            <p>IIBA Certified CCBA</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/IIBA/IIBA Certified CPOA.webp')} alt="Certification 3" />
            <p>IIBA Certified CPOA</p>
          </div>
        </div>
      </section>
      {/* WhatsApp Icon */}
      <a
          href="https://wa.me/919890142308" // Your WhatsApp number
          className="whatsapp-float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('../Image/newWhatsapp.png')} alt="WhatsApp" />
        </a>
    </div>
    <Footer />
    </>
  );
};

export default VendorIIBA;