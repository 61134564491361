import React from 'react';
import '../Styling/Vendor.css'; // Import the same CSS file
import Header from './Header';
import Footer from './Footer';

const VendorGoogle = () => {
  return (
    <>
    <Header />
    <div className="vendor-page">
      {/* Vendor Name Section */}
      <section className="vendor-name">
        <h1>Google Cloud Certifications</h1>
        <p style={{textAlign:'left'}}>Google Cloud is a suite of cloud computing services offered by Google that provides a range of cloud-based solutions for businesses, developers, and IT professionals. It includes a variety of tools and services for computing, storage, machine learning, data analytics, networking, security, and more. Google Cloud includes services for computing, data storage, data analytics, machine learning, networking, and loT.</p>
        <a href="https://wa.me/919890142308" className="whatsapp-button">
            <span role="img" aria-label="whatsapp">💬</span> Enquire Now
          </a>
      </section>

      {/* Certifications Section */}
      <section className="certifications">

        <div className="certification-grid">
          <div className="certification-item">
            <img src={require('../Image/Google/WorkspaceAdministrator.png')} alt="Certification 1" />
            <p>Google Cloud Certified Workspace Administrator</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/Google/MachineLearningEngineer.png')} alt="Certification 2" />
            <p>Google Cloud Certified Machine Learning Engineer</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/Google/DataEngineer.png')} alt="Certification 3" />
            <p>Google Cloud Certified Data Engineer</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/Google/SecurityEngineer.png')} alt="Certification 3" />
            <p>Google Cloud Certified Security Engineer</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/Google/NetworkEngineer.png')} alt="Certification 3" />
            <p>Google Cloud Certified Network Engineer</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/Google/DevOpsEngineer.png')} alt="Certification 3" />
            <p>Google Cloud Certified DevOps Engineer</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/Google/CloudArchitect.png')} alt="Certification 3" />
            <p>Google Cloud Certified Cloud Architect</p>
          </div>
        </div>
      </section>
      {/* WhatsApp Icon */}
      <a
          href="https://wa.me/919890142308" // Your WhatsApp number
          className="whatsapp-float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('../Image/newWhatsapp.png')} alt="WhatsApp" />
        </a>
    </div>
    <Footer />
    </>
  );
};

export default VendorGoogle;