// src/pages/VendorMicrosoft.jsx
import React from 'react';
import '../Styling/Vendor.css'; // Import the same CSS file
import Header from './Header';
import Footer from './Footer';

const VendorMicrosoft = () => {
  return (
    <>
    <Header />
    <div className="vendor-page">
      {/* Vendor Name Section */}
      <section className="vendor-name">
        <h1>Microsoft Certifications</h1>
        <p style={{textAlign:'left'}}>Microsoft Certification demonstrates that you're proficient in the real-world skills associated with recognized industry roles. It helps to verifies that you know how to apply technical knowledge to solve today's business challenges and how to achieve the goals. Microsoft certifications can give your profile some well-deserved padding, and also greatly improving your job prospects. Most popular MICROSOFT Certifications are:</p>
        <ul>
            <li style={{textAlign:'left'}}>MICROSOFT AZURE FUNDAMENTALS</li>
            <li style={{textAlign:'left'}}>MICROSOFT AZURE ADMINISTRATOR</li>
            <li style={{textAlign:'left'}}>MICROSOFT DEVELOPER</li>

        </ul>
        <a href="https://wa.me/919890142308" className="whatsapp-button">
            <span role="img" aria-label="whatsapp">💬</span> Enquire Now
          </a>
      </section>

      {/* Certifications Section */}
      <section className="certifications">

        <div className="certification-grid">
          <div className="certification-item">
            <img src={require('../Image/Microsoft/fundamentals.png')} alt="Certification 1" />
            <p>Fundamentals</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/Microsoft/associate.png')} alt="Certification 2" />
            <p>Associate</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/Microsoft/expert.png')} alt="Certification 3" />
            <p>Expert</p>
          </div>
          {/* Add more certification items as needed */}
        </div>
      </section>
      {/* WhatsApp Icon */}
      <a
          href="https://wa.me/919890142308" // Your WhatsApp number
          className="whatsapp-float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('../Image/newWhatsapp.png')} alt="WhatsApp" />
        </a>
    </div>
    <Footer />
    </>
  );
};

export default VendorMicrosoft;
