import React from 'react';
import '../Styling/Vendor.css'; // Import the same CSS file
import Header from './Header';
import Footer from './Footer';

const VendorAWS = () => {
  return (
    <>
    <Header />
    <div className="vendor-page">
      {/* Vendor Name Section */}
      <section className="vendor-name">
        <h1>AWS Certifications</h1>
        <p style={{textAlign:'left'}}>Amazon Web Services (AWS) is the most popular form of Cloud Computing that is world's most used cloud platform. AWS platform is the most secure and flexible cloud computing environment. The course will help you identify the appropriate AWS service based on data, compute or security requirements. If you have a potential and will to be succeed, then we'll provide you with the best-in-class, super-flexible and easy-to-redeem discounted vouchers! Most popular AWS Certifications are:</p>
            <ul>
              <li style={{textAlign:'left'}}>AWS Solutions Architect Associate</li>
              <li style={{textAlign:'left'}}>AWS Solutions Architect Professional</li>
              <li style={{textAlign:'left'}}>AWS Cloud Practitioner</li>

            </ul>
            <a href="https://wa.me/919890142308" className="whatsapp-button">
            <span role="img" aria-label="whatsapp">💬</span> Enquire Now
          </a>
      </section>

      {/* Certifications Section */}
      <section className="certifications">

        <div className="certification-grid">
          <div className="certification-item">
            <img src={require('../Image/AWS/CloudPractitioner.png')} alt="Certification 1" />
            <p>AWS Certified Cloud Practitioner Foundational</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/AWS/DeveloperAssociate.png')} alt="Certification 2" />
            <p>AWS Certified Developer-Associate</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/AWS/NetworkingSpeciality.png')} alt="Certification 3" />
            <p>AWS Certified Advanced Networking-Speciality</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/AWS/DataAnalyticsSpeciality.png')} alt="Certification 3" />
            <p>AWS Certified Data Analytics-Speciality</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/AWS/DatabaseSpeciality.png')} alt="Certification 3" />
            <p>AWS Certified Database-Speciality</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/AWS/DevOPsEngineerProfessional.png')} alt="Certification 3" />
            <p>AWS Certified DevOPs Engineer-Professional</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/AWS/MachineLearningSpeciality.png')} alt="Certification 3" />
            <p>AWS Certified Machine Learning-Speciality</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/AWS/SecuritySpeciality.png')} alt="Certification 3" />
            <p>AWS Certified Security-Speciality</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/AWS/SolutionsArchitectSpeciality.png')} alt="Certification 3" />
            <p>AWS Certified Solutions Architect-Speciality</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/AWS/SysOpsAdministratorAssociate.png')} alt="Certification 3" />
            <p>AWS Certified SysOps Administrator-Associate</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/AWS/SolutionsArchitectProfessional.png')} alt="Certification 3" />
            <p>AWS Certified Solutions Architect-professional</p>
          </div>
        </div>
      </section>
      {/* WhatsApp Icon */}
      <a
          href="https://wa.me/919890142308" // Your WhatsApp number
          className="whatsapp-float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('../Image/newWhatsapp.png')} alt="WhatsApp" />
        </a>
    </div>
    <Footer />
    </>
  );
};

export default VendorAWS;