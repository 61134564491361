import React from 'react';
import { Link } from 'react-router-dom';
import '../Styling/VendorPage.css';
import Header from './Header';
import Footer from './Footer';

const VendorPage = () => {
  const vendors = [
    { name: "Microsoft", img: require('../Image/microSoft.jpg'), link: "/microsoft" },
    { name: "AWS", img: require('../Image/AWS.jpg'), link: "/aws" },
    { name: "Google Cloud", img: require('../Image/googleCloud.jpg'), link: "/google-cloud" },
    { name: "CompTIA", img: require('../Image/compTia.png'), link: "/comptia" },
    { name: "PMI", img: require('../Image/PMI.jpg'), link: "/pmi" },
    { name: "IIBA", img: require('../Image/IIBA.jpg'), link: "/iiba" },
    { name: "Salesforce", img: require('../Image/salesforce.jpeg'), link: "/" },
    { name: "CIA", img: require('../Image/cia.png'), link: "/" },
    { name: "Cisco", img: require('../Image/cisco.png'), link: "/" },
    { name: "ISACA", img: require('../Image/isaca.jpeg'), link: "/" },
    { name: "Oracle", img: require('../Image/oracle.png'), link: "/" },
    { name: "SAFe", img: require('../Image/safe.png'), link: "/" },
    { name: "ServiceNow", img: require('../Image/servicenow.png'), link: "/" },
    { name: "GAQM", img: require('../Image/gaqm.jpg'), link: "/" },
    { name: "SAP", img: require('../Image/sap.png'), link: "/" },
  ];

  return (
    <>
    <Header />
    <div className="vendor-page-container">
      <h2 className="vendor-page-title">All Courses</h2>
      <div className="vendor-page-grid">
        {vendors.map((vendor, index) => (
          <Link to={vendor.link} key={index} className="vendor-page-link">
            <div className="vendor-page-item" style={{ backgroundImage: `url(${vendor.img})` }}>
              <div className="vendor-page-name">{vendor.name}</div>
            </div>
          </Link>
        ))}
      </div>
    </div>
     {/* WhatsApp Icon */}
     <a
          href="https://wa.me/919890142308" // Your WhatsApp number
          className="whatsapp-float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('../Image/newWhatsapp.png')} alt="WhatsApp" />
        </a>
    <Footer />
    </>
  );
};

export default VendorPage;

