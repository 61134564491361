import React from 'react';
import '../Styling/Footer.css';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* <div className="footer-section about">
          <img src={logo} alt="Logo" />
          <p>
            We provide world-class IT certification training to help you
            advance your career. Our expert trainers are dedicated to your
            success.
          </p>
        </div> */}
        <div className="footer-section links">
          <h2>Quick Links</h2>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/aboutus">About Us</a></li>
          </ul>
        </div>
        <div className="footer-section contact">
          <h2>Contact Us</h2>
          <p>Email: quantifylogic@gmail.com</p>
          <p>Phone: +91-9890142308</p>
        </div>
        <div className="footer-section address">
          <h2>Address</h2>
          <p>Kamalraj Pasaydan, 2nd Floor, Office No D206, Alandi Rd, above Global Mart</p>
          <p>Dighi, Pune, Maharashtra 411015</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} QuantifyLogic Solutions Pvt. Ltd. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
