

// import React, { useRef, useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import '../Styling/Home.css';
// import Header from './Header';
// import Footer from './Footer';

// const Home = () => {
//   const vendorGridRef = useRef(null);
//   const [activeIndex, setActiveIndex] = useState(0);
//   const [countersAnimated, setCountersAnimated] = useState(false);

//   const vendors = [
//     { name: "Microsoft", img: require('../Image/microSoft.jpg'), link: "/microsoft" },
//     { name: "AWS", img: require('../Image/AWS.jpg'), link: "/aws" },
//     { name: "Google Cloud", img: require('../Image/googleCloud.jpg'), link: "/google-cloud" },
//     { name: "CompTIA", img: require('../Image/compTia.png'), link: "/comptia" },
//     { name: "PMI", img: require('../Image/PMI.jpg'), link: "/pmi" },
//     { name: "IIBA", img: require('../Image/IIBA.jpg'), link: "/iiba" },
//     { name: "Salesforce", img: require('../Image/salesforce.jpeg'), link: "/" },
//     { name: "CIA", img: require('../Image/cia.png'), link: "/" },
//     { name: "Cisco", img: require('../Image/cisco.png'), link: "/" },
//     { name: "ISACA", img: require('../Image/isaca.jpeg'), link: "/" },
//     { name: "Oracle", img: require('../Image/oracle.png'), link: "/" },
//     { name: "SAFe", img: require('../Image/safe.png'), link: "/" },
//     { name: "ServiceNow", img: require('../Image/servicenow.png'), link: "/" },
//     { name: "GAQM", img: require('../Image/gaqm.jpg'), link: "/" },
//     { name: "SAP", img: require('../Image/sap.png'), link: "/" },
//   ];

//   const reviews = [
//     { name: "John Doe", review: "The training was fantastic! The trainers were very knowledgeable.", img: require('../Image/client.png') },
//     { name: "Jane Smith", review: "I learned so much from the courses. Highly recommend!", img: require('../Image/client.png') },
//     { name: "Sam Wilson", review: "Great experience! The flexible timings helped a lot.", img: require('../Image/client.png') },
//   ];

//   const scrollLeft = () => {
//     if (vendorGridRef.current) {
//       vendorGridRef.current.scrollBy({ left: -300, behavior: 'smooth' });
//       updateActiveIndex('left');
//     }
//   };

//   const scrollRight = () => {
//     if (vendorGridRef.current) {
//       vendorGridRef.current.scrollBy({ left: 300, behavior: 'smooth' });
//       updateActiveIndex('right');
//     }
//   };

//   const updateActiveIndex = (direction) => {
//     const totalVendors = vendors.length;
//     if (direction === 'left') {
//       setActiveIndex((prevIndex) => (prevIndex - 1 + totalVendors) % totalVendors);
//     } else if (direction === 'right') {
//       setActiveIndex((prevIndex) => (prevIndex + 1) % totalVendors);
//     }
//   };

//   // Function to check if an element is in the viewport
//   const isInViewport = (element) => {
//     const rect = element.getBoundingClientRect();
//     return rect.top <= (window.innerHeight || document.documentElement.clientHeight);
//   };

//   // Function to add the 'visible' class when the element enters the viewport
//   const animateOnScroll = () => {
//     const elements = document.querySelectorAll('.fade-in-up');
//     elements.forEach((element) => {
//       if (isInViewport(element)) {
//         element.classList.add('visible');
//       }
//     });
//   };

//   // Counter animation for facts section numbers
//   const animateCounters = () => {
//     const counters = document.querySelectorAll('.counter');
//     counters.forEach((counter) => {
//       const updateCount = () => {
//         const target = +counter.getAttribute('data-target');
//         const count = +counter.innerText.replace('+', ''); // Remove + before parsing to number
//         const increment = target / 200;

//         if (count < target) {
//           counter.innerText = Math.ceil(count + increment) + '+';
//           setTimeout(updateCount, 20);
//         } else {
//           counter.innerText = target + '+';
//         }
//       };
//       updateCount();
//     });
//   };

//   // Listen for scroll and load events
//   useEffect(() => {
//     const handleScrollAndLoad = () => {
//       animateOnScroll();
//       if (!countersAnimated) {
//         const factsSection = document.querySelector('.facts');
//         if (factsSection && isInViewport(factsSection)) {
//           animateCounters();
//           setCountersAnimated(true); // Ensures the counters only animate once
//         }
//       }
//     };

//     window.addEventListener('scroll', handleScrollAndLoad);
//     window.addEventListener('load', handleScrollAndLoad);

//     return () => {
//       window.removeEventListener('scroll', handleScrollAndLoad);
//       window.removeEventListener('load', handleScrollAndLoad);
//     };
//   }, [countersAnimated]);

//   return (
//     <>
//       <Header />
//       {/* Announcement Section */}
//       <div className="announcement">
//               <p>🎉 Special Offer: Get 20% Off on All Courses! Limited Time Only!</p>
//             </div>
//       <div className="home">
//         {/* Hero Section */}
//         <header className="hero">
            
//           <div className="hero-content">
//             <h1>EMPOWERING YOUR IT FUTURE</h1>
//             <h2>Get Trained With Experienced Trainers!</h2>
//             <p>Expand your skills and upscale your career!</p>
//             <p>Learn from home with our world-class faculty of trainers!</p>
//             <a href="https://wa.me/919890142308" className="whatsapp-button">
//               <span role="img" aria-label="whatsapp">💬</span> Enquire Now
//             </a>
//           </div>
//         </header>

//         {/* Vendor List Section */}
//         <section className="vendors">
//           <h2 className='vendor-page-title'>Vendors</h2>
//           <div className="vendor-container">
//             <button className="scroll-button left" onClick={scrollLeft}>❮</button>
//             <div className="vendor-grid" ref={vendorGridRef}>
//               {vendors.map((vendor, index) => (
//                 <Link to={vendor.link} className="vendor-link" key={index}>
//                   <div className="vendor-item" style={{ backgroundImage: `url(${vendor.img})` }}>
//                     <div className="know-more">Know More</div>
//                   </div>
//                 </Link>
//               ))}
//             </div>
//             <button className="scroll-button right" onClick={scrollRight}>❯</button>

//             {/* Dynamic Dots */}
//             <div className="dots-navigation">
//               {vendors.map((_, index) => (
//                 <span
//                   key={index}
//                   className={`dot ${index === activeIndex ? 'active' : ''}`}
//                   onClick={() => setActiveIndex(index)}
//                 ></span>
//               ))}
//             </div>
//           </div>
//         </section>

//         {/* Facts Section with Animated Numbers */}
//         <section className="facts">
//           <h2 className='vendor-page-title'>Our Impact</h2>
//           <div className="facts-grid">
//             <div className="fact-item fade-in-up">
//               <h3 className="counter" data-target="50">0</h3>
//               <p>Mentors</p>
//             </div>
//             <div className="fact-item fade-in-up">
//               <h3 className="counter" data-target="10000">0</h3>
//               <p>Candidates Trained</p>
//             </div>
//             <div className="fact-item fade-in-up">
//               <h3 className="counter" data-target="30">0</h3>
//               <p>Vendors</p>
//             </div>
//           </div>
          
//         </section>

//         {/* Reviews Section */}
//         <section className="reviews">
//           <h2 className="vendor-page-title">Client Reviews</h2>
//           <div className="reviews-grid">
//             {reviews.map((review, index) => (
//               <div className="review-item fade-in-up" key={index}>
//                 <img src={review.img} alt={review.name} className="review-img" />
//                 <p className="review-text">"{review.review}"</p>
//                 <p className="review-name">- {review.name}</p>
//               </div>
//             ))}
//           </div>
//         </section>

//         {/* Contact Us Section */}
//         <section className="contact-us">
//           <div className="contact-heading">
//             <h1>Get in touch !</h1>
//             <a href="https://wa.me/919890142308" className="whatsapp-button">
//               <span role="img" aria-label="whatsapp">💬</span> +91-9890142308
//             </a>
//           </div>
//           <div className="google-form fade-in-up">
//             <iframe
//               src="https://docs.google.com/forms/d/e/1FAIpQLSdIQM6u0MfFRlkD3O8zCPdxvs_NrW72etx6h52Iu7dd8-VOnQ/viewform?usp=sf_link"
//               width="100%"
//               height="500px"
//               frameBorder="0"
//               marginHeight="0"
//               marginWidth="0"
//               title="Contact Us Form"
//             >
//               Loading…
//             </iframe>
//           </div>
//         </section>

//         {/* WhatsApp Icon */}
//         <a
//           href="https://wa.me/919890142308"
//           className="whatsapp-float"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <img src={require('../Image/newWhatsapp.png')} alt="WhatsApp" />
//         </a>

//         <Footer />
//       </div>
//     </>
//   );
// };

// export default Home;











import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../Styling/Home.css';
import Header from './Header';
import Footer from './Footer';

const Home = () => {

  const [activeIndex, setActiveIndex] = useState(0);
  const [countersAnimated, setCountersAnimated] = useState(false);

  const vendors = [
    { name: "Microsoft", img: require('../Image/microSoft.jpg'), link: "/microsoft" },
    { name: "AWS", img: require('../Image/AWS.jpg'), link: "/aws" },
    { name: "Google Cloud", img: require('../Image/googleCloud.jpg'), link: "/google-cloud" },
    { name: "CompTIA", img: require('../Image/compTia.png'), link: "/comptia" },
    { name: "PMI", img: require('../Image/PMI.jpg'), link: "/pmi" },
    { name: "IIBA", img: require('../Image/IIBA.jpg'), link: "/iiba" },
    { name: "Salesforce", img: require('../Image/salesforce.jpeg'), link: "/" },
    { name: "CIA", img: require('../Image/cia.png'), link: "/" },
    { name: "Cisco", img: require('../Image/cisco.png'), link: "/" },
    { name: "ISACA", img: require('../Image/isaca.jpeg'), link: "/" },
    { name: "Oracle", img: require('../Image/oracle.png'), link: "/" },
    { name: "SAFe", img: require('../Image/safe.png'), link: "/" },
    { name: "ServiceNow", img: require('../Image/servicenow.png'), link: "/" },
    { name: "GAQM", img: require('../Image/gaqm.jpg'), link: "/" },
    { name: "SAP", img: require('../Image/sap.png'), link: "/" },
  ];

//   const reviews = [
//     { name: "John Doe", review: "The training was fantastic! The trainers were very knowledgeable.", img: require('../Image/client.png') },
//     { name: "Jane Smith", review: "I learned so much from the courses. Highly recommend!", img: require('../Image/client.png') },
//     { name: "Sam Wilson", review: "Great experience! The flexible timings helped a lot.", img: require('../Image/client.png') },
//   ];

  const scrollLeft = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + vendors.length) % vendors.length);
  };

  const scrollRight = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % vendors.length);
  };

  const getVendorClass = (index) => {
    if (index === activeIndex) return 'active';
    if (index === (activeIndex - 1 + vendors.length) % vendors.length) return 'left';
    if (index === (activeIndex + 1) % vendors.length) return 'right';
    return '';
  };

  // Function to check if an element is in the viewport
  const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return rect.top <= (window.innerHeight || document.documentElement.clientHeight);
  };

  // Function to add the 'visible' class when the element enters the viewport
  const animateOnScroll = () => {
    const elements = document.querySelectorAll('.fade-in-up');
    elements.forEach((element) => {
      if (isInViewport(element)) {
        element.classList.add('visible');
      }
    });
  };

  // Counter animation for facts section numbers
  const animateCounters = () => {
    const counters = document.querySelectorAll('.counter');
    counters.forEach((counter) => {
      const updateCount = () => {
        const target = +counter.getAttribute('data-target');
        const count = +counter.innerText.replace('+', ''); // Remove + before parsing to number
        const increment = target / 200;

        if (count < target) {
          counter.innerText = Math.ceil(count + increment) + '+';
          setTimeout(updateCount, 20);
        } else {
          counter.innerText = target + '+';
        }
      };
      updateCount();
    });
  };

  // Listen for scroll and load events
  useEffect(() => {
    const handleScrollAndLoad = () => {
      animateOnScroll();
      if (!countersAnimated) {
        const factsSection = document.querySelector('.facts');
        if (factsSection && isInViewport(factsSection)) {
          animateCounters();
          setCountersAnimated(true); // Ensures the counters only animate once
        }
      }
    };

    window.addEventListener('scroll', handleScrollAndLoad);
    window.addEventListener('load', handleScrollAndLoad);

    return () => {
      window.removeEventListener('scroll', handleScrollAndLoad);
      window.removeEventListener('load', handleScrollAndLoad);
    };
  }, [countersAnimated]);

  return (
    <>
      <Header />
      {/* Announcement Section */}
      <div className="announcement">
              <p>🎉 Special Offer: Get 20% Off on All Courses! Limited Time Only!</p>
            </div>
      <div className="home">
        {/* Hero Section */}
        <header className="hero">
            
          <div className="hero-content">
            <h1>EMPOWERING YOUR IT FUTURE</h1>
            <h2>Get Trained With Experienced Trainers!</h2>
            <p>Expand your skills and upscale your career!</p>
            <p>Learn from home with our world-class faculty of trainers!</p>
            <a href="https://wa.me/919890142308" className="whatsapp-button">
              <span role="img" aria-label="whatsapp">💬</span> Enquire Now
            </a>
          </div>
        </header>

        {/* Vendor List Section */}
        <section className="vendors">
        <h2 className='vendor-page-title'>Courses</h2>
        <div className="vendor-container">
          <button className="scroll-button left" onClick={scrollLeft}>❮</button>
          <div className="vendor-grid">
            {vendors.map((vendor, index) => (
              <Link to={vendor.link} className={`vendor-item ${getVendorClass(index)}`} key={index}
                style={{ backgroundImage: `url(${vendor.img})` }}>
                {/* <div className="know-more">Know More</div> */}
              </Link>
            ))}
          </div>
        <button className="scroll-button right" onClick={scrollRight}>❯</button>

            {/* Dynamic Dots */}
            {/* <div className="dots-navigation">
              {vendors.map((_, index) => (
                <span
                  key={index}
                  className={`dot ${index === activeIndex ? 'active' : ''}`}
                  onClick={() => setActiveIndex(index)}
                ></span>
              ))}
            </div> */}
          </div>
        </section>

        <section class="facts">
  <h2 class="vendor-page-title">Our Impact</h2> 
  <div class="facts-container">
    
        <div class="fact-item">
          <h3 class="counter" data-target="50">0</h3>
          <p>Mentors</p>
        </div>
        <div class="fact-item">
          <h3 class="counter" data-target="10000">0</h3>
          <p>Candidates Trained</p>
        </div>
        <div class="fact-item">
          <h3 class="counter" data-target="30">0</h3>
          <p>Vendors</p>
        </div>
  </div>
</section>





        {/* Reviews Section */}
        {/* <section className="reviews">
          <h2 className="vendor-page-title">Client Reviews</h2>
          <div className="reviews-grid">
            {reviews.map((review, index) => (
              <div className="review-item fade-in-up" key={index}>
                <img src={review.img} alt={review.name} className="review-img" />
                <p className="review-text">"{review.review}"</p>
                <p className="review-name">- {review.name}</p>
              </div>
            ))}
          </div>
        </section> */}

        {/* Contact Us Section */}
        <section className="contact-us">
          <div className="contact-heading">
            <h1>Get in touch !</h1>
            <a href="https://wa.me/919890142308" className="whatsapp-button">
              <span role="img" aria-label="whatsapp">💬</span> +91-9890142308
            </a>
          </div>
          <div className="google-form fade-in-up">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSdIQM6u0MfFRlkD3O8zCPdxvs_NrW72etx6h52Iu7dd8-VOnQ/viewform?usp=sf_link"
              width="100%"
              height="500px"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="Contact Us Form"
            >
              Loading…
            </iframe>
          </div>
        </section>

        {/* WhatsApp Icon */}
        <a
          href="https://wa.me/919890142308"
          className="whatsapp-float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('../Image/newWhatsapp.png')} alt="WhatsApp" />
        </a>

        <Footer />
      </div>
    </>
  );
};

export default Home;