import React from 'react';
import '../Styling/Vendor.css'; // Import the same CSS file
import Header from './Header';
import Footer from './Footer';

const VendorComptia = () => {
  return (
    <>
    <Header />
    <div className="vendor-page">
      {/* Vendor Name Section */}
      <section className="vendor-name">
        <h1>CompTia Certifications</h1>
        <p style={{textAlign:'left'}}>CompTIA's vendor-neutral certification program is one of the best recognized in the IT industry. Since CompTIA developed its A+ credential in 1993, it has issued more than two million certifications. CompTIA certifications are grouped by skill set. Currently, CompTIA certs fall info four areas: Core, Infrastructure, Cybersecurity and Additional Professional certifications. Most popular COMPTIA Certifications are:</p>
            <ul>
              <li style={{textAlign:'left'}}>COMPTIA SECURITY+ (S+)</li>
              <li style={{textAlign:'left'}}>COMPTIA NETWORK+ (N+)</li>
              <li style={{textAlign:'left'}}>COMPTIA CYSA+</li>
              <li style={{textAlign:'left'}}>COMPTIA A+</li>
            </ul>
        <a href="https://wa.me/919890142308" className="whatsapp-button">
            <span role="img" aria-label="whatsapp">💬</span> Enquire Now
          </a>
      </section>

      {/* Certifications Section */}
      <section className="certifications">

        <div className="certification-grid">
          <div className="certification-item">
            <img src={require('../Image/Comptia/COMPTIA Security + Certification.png')} alt="Certification 1" />
            <p>COMPTIA Security+ Certification</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/Comptia/COMPTIA CySA+ Certification.png')} alt="Certification 2" />
            <p>COMPTIA CySA+ Certification</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/Comptia/COMPTIA A+Certification.jpg')} alt="Certification 3" />
            <p>COMPTIA A+ Certification</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/Comptia/COMPTIA Network+ Certification.png')} alt="Certification 3" />
            <p>COMPTIA Network+ Certification</p>
          </div>
          <div className="certification-item">
            <img src={require('../Image/Comptia/COMPTIA Cloud+ Certification.png')} alt="Certification 3" />
            <p>COMPTIA Cloud+ Certification</p>
          </div>
        </div>
      </section>
      {/* WhatsApp Icon */}
      <a
          href="https://wa.me/919890142308" // Your WhatsApp number
          className="whatsapp-float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={require('../Image/newWhatsapp.png')} alt="WhatsApp" />
        </a>
    </div>
    <Footer />
    </>
  );
};

export default VendorComptia;